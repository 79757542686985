import React, {useEffect} from "react";
import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import {InteractionStatus} from "@azure/msal-browser";
import RTKQueryFeedback from "../components/common/RTKQueryFeedback";
import {hasEveryOf, hasSomeOf} from "../utils/user/user-utils";
import {useGetCurrentUserQuery} from "../store/apis/ccs";

interface AuthorizationGuardProps {
  children: React.ReactNode;
  userHasSomeOf?: string[];
  userHasEveryOf?: string[];
  redirectToUnauthorized?: boolean;
  showErrorView?: boolean;
}

export const AuthorizationGuard = ({
                                     children,
                                     userHasSomeOf = undefined,
                                     userHasEveryOf = undefined,
                                     redirectToUnauthorized = undefined,
                                     showErrorView = undefined
                                   } : AuthorizationGuardProps) => {
  const {inProgress} = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const {data} = useGetCurrentUserQuery(undefined, {skip: Boolean(localStorage.getItem('user') || !localStorage.getItem('accessToken'))});
  const user = data || localStorage.getItem('user') && JSON.parse(localStorage.getItem('user') as string);

  useEffect(() => {
      if (data) {
        localStorage.setItem('user', JSON.stringify({...data, picture: undefined}));
      }
  }, [data]);

  if (InteractionStatus.Startup === inProgress || !isAuthenticated || !user) {
    return (
        <>
          {/*{isError && showErrorView && <GenericError />}*/}
          <RTKQueryFeedback isLoading={InteractionStatus.Startup === inProgress} /*error={error}*/ />
        </>
    );
  }


  if (!(user && hasSomeOf(user, userHasSomeOf) && hasEveryOf(user, userHasEveryOf))) {
    if (redirectToUnauthorized) window.location.href = '/403';
    return null;
  }

  return <>{children}</>;
};

export default AuthorizationGuard;
