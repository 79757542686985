//@ts-nocheck
import axios, {AxiosRequestHeaders} from 'axios';
import { msalInstance } from './msal-instance';
import {API_ENDPOINT, MSA_SCOPES_AZURE, MSA_SCOPES_CCS} from '../config/settings';
import {retrieveCurrentTokenPerScope} from "sata-auth";

const authAxiosInstance = axios.create({
  baseURL: API_ENDPOINT
});

authAxiosInstance.interceptors.request.use(
    async (config) => {
      let token = localStorage.getItem('accessToken') || null;

      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`
        } as AxiosRequestHeaders;
      }

      return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

authAxiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            try {
                originalRequest._retry = true;
                const token = await msalInstance.acquireTokenSilent({scopes: MSA_SCOPES_CCS as string[], forceRefresh: true});
                if (token) {
                  localStorage.setItem('accessToken', token.accessToken);
                }
                originalRequest.headers.Authorization = `Bearer ${token.accessToken}`;
                return axios(originalRequest); //recall Api with new token
            } catch (error) {
                localStorage.removeItem('accessToken');
                msalInstance.loginRedirect();
                window.location.href = '/auth';
            }
        }
        return Promise.reject(error);
    }
);

const axiosAzureInstance = axios.create({
  baseURL: API_ENDPOINT
});

axiosAzureInstance.interceptors.request.use(
  async (config) => {
    let token
    try {
      token = await retrieveCurrentTokenPerScope(msalInstance, MSA_SCOPES_AZURE || '');
    } catch (e) {
      // token = await msalInstance.acquireTokenRedirect({scopes: MSA_SCOPES_AZURE});
    }
    // @ts-ignore
    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { authAxiosInstance, axiosAzureInstance };

export const mockRoutes = [
  {method: 'GET', path: '/users', response: {data: []}},
  {method: 'GET', path: '/roles', response: {data: []}},
  {method: 'GET', path: '/offices', response: {data: []}},
  {method: 'GET', path: '/forms-of-payment', response: {data: []}},
  {method: 'GET', path: '/issue-offices', response: {data: [{issueOfficeId: 1, description: 'PDLS408AA', active: true}]}},
];

export const mockResolver = ({method = 'GET', url}) => {
  const route = mockRoutes.find(r => r.method.toUpperCase() === method && url.includes(r.path));
  if (!route) throw new Error('Not found');
  return {data: route.response};
}
